// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-book-jsx": () => import("./../../../src/pages/book.jsx" /* webpackChunkName: "component---src-pages-book-jsx" */),
  "component---src-pages-directing-jsx": () => import("./../../../src/pages/directing.jsx" /* webpackChunkName: "component---src-pages-directing-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-plays-jsx": () => import("./../../../src/pages/plays.jsx" /* webpackChunkName: "component---src-pages-plays-jsx" */),
  "component---src-pages-press-jsx": () => import("./../../../src/pages/press.jsx" /* webpackChunkName: "component---src-pages-press-jsx" */),
  "component---src-pages-teaching-jsx": () => import("./../../../src/pages/teaching.jsx" /* webpackChunkName: "component---src-pages-teaching-jsx" */)
}

